import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import { listViewMode, cardViewMode } from "../redux/propertySlice";

const ViewModeButton = () => {
  const dispatch = useDispatch();
  const { viewMode } = useSelector((state) => state.property);

  const isListView = () => {
    return viewMode === "list";
  };

  const isCardView = () => {
    return viewMode === "card";
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          border: isListView() ? "1.5px solid #0d6986" : "1px solid #707070",
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          borderRight: isListView() ? "1.5px solid #0d6986" : "none",
        }}
      >
        <IconButton disabled={isListView()} sx={{ height: "4vh" }}>
          <FormatListBulletedRoundedIcon
            sx={{ width: "1.5vw" }}
            htmlColor={isListView() ? "#0d6986" : "#707070"}
            onClick={() => dispatch(listViewMode())}
          />
        </IconButton>
      </div>
      <div
        style={{
          border: isCardView() ? "1.5px solid #0d6986" : "1px solid #707070",
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          borderLeft: isCardView() ? "1.5px solid #0d6986" : "none",
        }}
      >
        <IconButton disabled={isCardView()} sx={{ height: "4vh" }}>
          <GridViewRoundedIcon
            sx={{ width: "1.5vw" }}
            htmlColor={isCardView() ? "#0d6986" : "#707070"}
            onClick={() => dispatch(cardViewMode())}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default ViewModeButton;
