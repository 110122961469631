import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { showFilter, hideFilter } from "../redux/propertySlice";
import { Path } from "../utils/PathUtils";

const FilterButton = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { display } = useSelector((state) => state.property.filter);

  const isActive = () => {
    return location.pathname.endsWith(Path.CONTACT);
  };

  return (
    <NavLink
      to={"#"}
      style={{
        display: isActive() ? "flex" : "none",
        alignItems: "center",
        justifyContent: "space-around",
        width: "4.7vw",
        marginLeft: "1vw",
        textDecoration: "none",
        paddingRight: "0.3vw",
        borderRadius: 10,
        border: display ? "1.5px solid #0d6986" : "1.5px solid #707070",
        fontWeight: "bold",
        fontSize: "0.9vw",
        color: display ? "#0d6986" : "#707070",
      }}
      onClick={() => {
        if (display) {
          dispatch(hideFilter());
        } else {
          dispatch(showFilter());
        }
      }}
    >
      <FilterListRoundedIcon htmlColor={display ? "#0d6986" : "#707070"} />
      Filter
    </NavLink>
  );
};

export default FilterButton;
